<template>
  <div class="">
    <div class="">
      <div class="">UID: {{currentUid}}</div>
      <div class="">Barcode: {{barcode}}</div>
    </div>

    <button
      type="button"
      class="btn btn-warning mb-3 mt-2"
      @click="setUid">
      Set UID
    </button>

    <div v-if="isLoaded" class="">
      [ STATUS ]

      <div v-if="errorMessage">
        {{errorMessage}}
      </div>

      <ul class="list-unstyled" v-else>
        <li>UID: {{status.uid}}</li>
        <li>Vendor ID: {{status.vendorId}}</li>
        <li>Product ID: {{status.productId}}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import {
  DETAIL_STATUS,
  SETUP_DEVICE_UID,
  DEVICE_SCANNED,
} from './graph'

export default {
  props: {
    deviceId: {
      type: Number,
      required: true
    },
    deviceType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      isLoaded: false,
      errorMessage: '',
      status: {
        uid: '',
        vendorId: '',
        productId: '',
      },
      currentUid: '',
      barcode: ''
    }
  },
  apollo: {
    $subscribe: {
      deviceScanned: {
        query () {
          return DEVICE_SCANNED(this.templateType)
        },
        variables () {
          return {
            uid: this.currentUid
          }
        },
        result (data) {
          this.barcode = data.data.deviceScanned.barcode
        },
        skip () {
          return !this.currentUid
        }
      },
    }
  },
  methods: {
    fetchData () {
      this.isLoaded = false
      this.errorMessage = ''
      this.$apollo.query({
        query: DETAIL_STATUS(this.templateType),
        variables: {
          deviceType: this.deviceType,
          deviceId: this.deviceId
        },
        fetchPolicy: 'no-cache'
      }).then(res => {
        const status = res.data.status
        Object.keys(this.status).forEach(key => {
          this.status[key] = status[key]
        })
      }).catch(() => {
        this.errorMessage = 'ไม่สามารถเชื่อมต่อได้'
      }).finally(() => {
        this.isLoaded = true
      })
    },
    setUid () {
      this.$apollo.mutate({
        mutation: SETUP_DEVICE_UID(this.templateType),
        variables: {
          deviceType: this.deviceType,
          deviceId: this.deviceId,
        }
      })
      .then(res => {
        this.currentUid = res.data.uid
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    }
  },
  created () {
    this.fetchData()
  }
}
</script>

<style lang="css" scoped>
</style>
